import { BaseService } from "../../service/base.service";

export class ProspectService extends BaseService {
  constructor() {
    super();
  }

  async scheduleDemo(form) {
    await this.client.post(
      '/opportunity/que/prospect/email',
      form,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

}

export const prospectService = new ProspectService();
