import { SectionLayout } from "../../components/shared/SectionLayout";
import React, { useState } from "react";
import { Button } from "@headlessui/react";
import { EmailModal } from "../../components/shared/modals/EmailModal";

export const Main = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 100%), url("/images/Main/main-bg.png")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <SectionLayout>
        <div className="container flex flex-col w-full items-center mt-12 md:mt-0 space-y-8 md:space-y-0 md:flex-row md:content-center">
          <div className="space-y-6 mt-18 md:mt-0">
            <div className="w-full text-center md:text-left">
              <h1 className="text-[34px] md:text-[80px] font-semibold text-white leading-10 md:leading-[80px] tracking-tight md:tracking-normal">
                R<span style={{ color: "#25ddbc" }}>ai</span>se the Bar on
                <br />
                <span>Seller Performance</span>
              </h1>
            </div>
            <div className="text-center md:text-left w-[80%] xs:w-[55%] mx-auto md:mx-0">
              <p className="text-xl lg:text-2xl font-medium text-white ">
                Shift the performance curve with{" "}
                <span style={{ color: "#25ddbc" }}>AI</span>-guided selling
              </p>
              <Button
                onClick={open}
                className="rounded-md bg-[#565ADD] mt-4 px-6 py-3 text-md font-semibold text-white focus:outline-none data-[hover]:bg-black/30 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                Schedule Demo
              </Button>
            </div>
          </div>
        </div>
      </SectionLayout>
      <EmailModal isOpen={isOpen} close={close} handleSubmit={() => {}} />
    </div>
  );
};
