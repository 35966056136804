import React from "react";
import { ContactForm } from "../../components/forms/ContactForm";
import { QoosLogo } from "../../components/shared/logos/QoosLogo";
import { useMediaQuery } from "react-responsive";

export const Contact = () => {
  const isMdScreen = useMediaQuery({ minWidth: 768 }); // Tailwind's 'md' breakpoint

  return (
    <>
      <div
        className="relative w-screen h-svh md:h-[800px] p-5 md:py-20 md:px-10 overflow-hidden"
        style={{
          backgroundImage: `url("/images/Contact/background.png")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full h-full flex flex-col lg:flex-row max-w-layout-content md:mx-auto pt-10 md:pt-0">
          <div className="flex flex-col w-full lg:w-1/2">
            <h1 className="text-[35px] md:text-[72px] text-white font-semibold text-center lg:text-start tracking-tight md:tracking-normal text-nowrap">
              Ready to See
            </h1>

            <div className="flex flex-row justify-center items-center gap-2 lg:justify-start">
              <QoosLogo width={isMdScreen ? "200" : "90"} />
              <h1 className="text-[35px] md:text-[72px] text-white font-semiboldtext-center lg:text-start tracking-tight md:tracking-normal leading-0 text-nowrap">
                in Action?
              </h1>
            </div>
          </div>

          <div className="lg:w-1/2 mt-10 lg:mt-0 flex justify-center lg:justify-end">
            <ContactForm />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

const Footer = () => {
  const isMdScreen = useMediaQuery({ minWidth: 768 }); // Tailwind's 'md' breakpoint
  const smScreenConstraint = useMediaQuery({ maxWidth: 640 }); // Tailwind's 'sm' breakpoint

  return (
    <div className="absolute md:relative  bottom-[26px] bg-[transparent] text-[white] z-20 flex flex-row justify-between items-center pl-0 w-full max-w-layout-content mx-auto pr-7 sm:p-5 sm:pr-20 md:px-0">
      <QoosLogo width={isMdScreen ? undefined : "60"} />

      <div className="flex flex-row align-center space-x-2 md:space-x-5">
        <a
          href="https://qoos.ai/terms"
          target="_blank"
          className="text-[white]"
        >
          Terms of Service
        </a>
        <a
          href="https://qoos.ai/privacy"
          target="_blank"
          className="text-[white]"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
