import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { HubspotLogo } from "../shared/logos/HubspotLogo";
import { LinkedInLogo } from "../shared/logos/LinkedInLogo";
import { MicrosoftLogo } from "../shared/logos/MicrosoftLogo";
import { MondayLogo } from "../shared/logos/MondayLogo";
import { SalesforceLogo } from "../shared/logos/SalesforceLogo";
import { ServiceNow } from "components/shared/logos/ServiceNow";

const logos = [
  <HubspotLogo />,
  <MondayLogo />,
  <ServiceNow />,
  <SalesforceLogo />,
  <MicrosoftLogo />,
  <LinkedInLogo />,
];

export const LogoCarousel = () => {
  return (
    <div className="w-full h-full overflow-auto">
      <InfiniteScroll
        dataLength={logos.length}
        hasMore={false}
        className="flex flex-nowrap columnn-gap-1"
        scrollableTarget="scrollableDiv"
        horizontal={true}
      >
        {logos.map((item, index) => (
          <div className="py-1" key={index}>
            {item}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};
