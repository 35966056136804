import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from 'views/Homepage';
import Privacy from 'views/Privacy';
import BullhornDashboard from 'views/static/dashboard/Bullhorn';
import JobAdderDashboard from 'views/static/dashboard/JobAdder';
import Terms from 'views/Terms';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard/jobadder" element={<JobAdderDashboard />} />
        <Route path="/dashboard/bullhorn" element={<BullhornDashboard />} />
      </Routes>
    </Router>
  );
};

export default App;