import React from 'react'

export const SalesforceLogo = () => {
    return (
        <svg width="228" height="145" viewBox="0 0 228 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dd_2491_38778)">
<rect x="16.9785" y="5" width="195" height="112" rx="16" fill="white"/>
<g clipPath="url(#clip0_2491_38778)">
<path d="M104.893 29.725C108.58 25.8875 113.705 23.5 119.38 23.5C126.93 23.5 133.505 27.7125 137.018 33.95C140.068 32.5875 143.443 31.825 146.98 31.825C160.593 31.825 171.618 42.95 171.618 56.6875C171.618 70.425 160.58 81.55 146.98 81.55C145.318 81.55 143.693 81.3875 142.13 81.0625C139.043 86.5625 133.155 90.2875 126.405 90.2875C123.58 90.2875 120.905 89.6375 118.53 88.475C115.405 95.8375 108.105 101 99.6176 101C91.1301 101 83.2301 95.4 80.3301 87.55C79.0676 87.8125 77.7551 87.9625 76.4051 87.9625C65.8676 87.9625 57.3301 79.3375 57.3301 68.6875C57.3301 61.55 61.1676 55.325 66.8676 51.9875C65.6926 49.2875 65.0426 46.3 65.0426 43.175C65.0426 30.925 74.9801 21 87.2426 21C94.4426 21 100.843 24.425 104.893 29.725Z" fill="#0D9DDA"/>
<path d="M73.8926 62.4873C73.8176 62.6748 73.9176 62.7123 73.9426 62.7498C74.1551 62.8998 74.3801 63.0123 74.5926 63.1498C75.7551 63.7623 76.8551 63.9498 78.0051 63.9498C80.3551 63.9498 81.8051 62.6998 81.8051 60.6998V60.6623C81.8051 58.8123 80.1551 58.1373 78.6176 57.6498L78.4176 57.5873C77.2551 57.2123 76.2551 56.8873 76.2551 56.1123V56.0748C76.2551 55.4248 76.8426 54.9373 77.7551 54.9373C78.7676 54.9373 79.9676 55.2748 80.7301 55.6998C80.7301 55.6998 80.9551 55.8498 81.0426 55.6248C81.0926 55.4998 81.4801 54.4498 81.5176 54.3373C81.5676 54.2123 81.4801 54.1248 81.4051 54.0748C80.5176 53.5373 79.3051 53.1748 78.0426 53.1748H77.8051C75.6551 53.1748 74.1551 54.4748 74.1551 56.3248V56.3623C74.1551 58.3248 75.8051 58.9498 77.3551 59.3998L77.6051 59.4748C78.7301 59.8248 79.7051 60.1248 79.7051 60.9123V60.9498C79.7051 61.6748 79.0676 62.2123 78.0551 62.2123C77.6551 62.2123 76.3926 62.2123 75.0301 61.3373C74.8676 61.2373 74.7676 61.1748 74.6426 61.0998C74.5801 61.0623 74.4051 60.9873 74.3301 61.1998L73.8676 62.4873H73.8926Z" fill="white"/>
<path d="M108.209 62.4873C108.134 62.6748 108.234 62.7123 108.259 62.7498C108.472 62.8998 108.697 63.0123 108.909 63.1498C110.072 63.7623 111.172 63.9498 112.322 63.9498C114.672 63.9498 116.122 62.6998 116.122 60.6998V60.6623C116.122 58.8123 114.484 58.1373 112.934 57.6498L112.734 57.5873C111.572 57.2123 110.572 56.8873 110.572 56.1123V56.0748C110.572 55.4248 111.159 54.9373 112.072 54.9373C113.084 54.9373 114.284 55.2748 115.047 55.6998C115.047 55.6998 115.272 55.8498 115.359 55.6248C115.409 55.4998 115.797 54.4498 115.834 54.3373C115.884 54.2123 115.797 54.1248 115.722 54.0748C114.834 53.5373 113.622 53.1748 112.359 53.1748H112.122C109.972 53.1748 108.472 54.4748 108.472 56.3248V56.3623C108.472 58.3248 110.122 58.9498 111.672 59.3998L111.922 59.4748C113.047 59.8248 114.022 60.1248 114.022 60.9123V60.9498C114.022 61.6748 113.384 62.2123 112.372 62.2123C111.972 62.2123 110.709 62.2123 109.347 61.3373C109.184 61.2373 109.084 61.1748 108.959 61.0998C108.922 61.0748 108.722 60.9998 108.647 61.1998L108.184 62.4873H108.209Z" fill="white"/>
<path d="M133.596 56.449C133.409 55.799 133.109 55.224 132.709 54.749C132.309 54.274 131.809 53.8865 131.209 53.6115C130.609 53.3365 129.909 53.1865 129.109 53.1865C128.309 53.1865 127.609 53.324 127.009 53.6115C126.409 53.8865 125.909 54.274 125.509 54.749C125.109 55.224 124.809 55.799 124.621 56.449C124.434 57.099 124.334 57.799 124.334 58.549C124.334 59.299 124.434 60.0115 124.621 60.649C124.809 61.299 125.109 61.874 125.509 62.349C125.909 62.824 126.421 63.2115 127.009 63.4865C127.609 63.7615 128.309 63.899 129.109 63.899C129.909 63.899 130.609 63.7615 131.209 63.4865C131.809 63.2115 132.309 62.8365 132.709 62.349C133.109 61.874 133.409 61.299 133.596 60.649C133.784 59.999 133.884 59.299 133.884 58.549C133.884 57.799 133.784 57.0865 133.596 56.449ZM131.634 58.549C131.634 59.6865 131.421 60.574 131.009 61.2115C130.596 61.8365 129.971 62.1365 129.109 62.1365C128.246 62.1365 127.621 61.8365 127.209 61.2115C126.796 60.574 126.584 59.6865 126.584 58.549C126.584 57.4115 126.796 56.524 127.196 55.899C127.609 55.274 128.221 54.974 129.096 54.974C129.971 54.974 130.584 55.274 130.996 55.899C131.409 56.524 131.621 57.4115 131.621 58.549" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M149.671 61.8377C149.609 61.6502 149.421 61.7252 149.421 61.7252C149.134 61.8377 148.834 61.9377 148.509 61.9877C148.184 62.0377 147.821 62.0627 147.434 62.0627C146.484 62.0627 145.734 61.7752 145.196 61.2252C144.659 60.6627 144.346 59.7627 144.359 58.5502C144.359 57.4377 144.634 56.6127 145.109 55.9752C145.584 55.3377 146.309 55.0252 147.284 55.0252C148.096 55.0252 148.709 55.1127 149.359 55.3252C149.359 55.3252 149.509 55.3877 149.584 55.1877C149.759 54.7127 149.884 54.3752 150.071 53.8502C150.121 53.7002 149.996 53.6377 149.946 53.6252C149.696 53.5252 149.096 53.3627 148.646 53.3002C148.221 53.2377 147.734 53.2002 147.184 53.2002C146.359 53.2002 145.634 53.3377 144.996 53.6252C144.371 53.9002 143.846 54.2877 143.421 54.7627C142.996 55.2377 142.684 55.8127 142.471 56.4627C142.259 57.1127 142.146 57.8127 142.146 58.5752C142.146 60.2002 142.584 61.5127 143.446 62.4752C144.309 63.4377 145.609 63.9252 147.309 63.9252C148.309 63.9252 149.334 63.7252 150.084 63.4252C150.084 63.4252 150.221 63.3627 150.159 63.1877L149.684 61.8627L149.671 61.8377Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M159.684 56.0865C159.521 55.4615 159.109 54.824 158.834 54.5365C158.409 54.074 157.996 53.7615 157.571 53.574C157.034 53.3365 156.384 53.1865 155.671 53.1865C154.846 53.1865 154.096 53.324 153.484 53.6115C152.871 53.899 152.359 54.2865 151.959 54.774C151.559 55.2615 151.259 55.8365 151.059 56.499C150.871 57.149 150.771 57.8615 150.771 58.6115C150.771 59.3615 150.871 60.0865 151.071 60.724C151.271 61.374 151.596 61.9365 152.034 62.4115C152.471 62.8865 153.034 63.2615 153.696 63.5115C154.359 63.774 155.171 63.899 156.096 63.899C158.009 63.899 159.021 63.4615 159.434 63.2365C159.509 63.199 159.571 63.124 159.496 62.924L159.059 61.7115C158.996 61.5365 158.809 61.599 158.809 61.599C158.334 61.774 157.659 62.0865 156.084 62.0865C155.059 62.0865 154.296 61.7865 153.821 61.3115C153.334 60.824 153.096 60.1115 153.046 59.1115H159.671C159.671 59.1115 159.846 59.1115 159.859 58.949C159.859 58.874 160.084 57.5865 159.659 56.099L159.684 56.0865ZM153.096 57.474C153.184 56.849 153.359 56.324 153.634 55.9115C154.034 55.2865 154.659 54.949 155.534 54.949C156.409 54.949 156.971 55.2865 157.384 55.9115C157.659 56.324 157.771 56.8615 157.821 57.474H153.096Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M106.625 56.0865C106.463 55.4615 106.05 54.824 105.775 54.5365C105.35 54.074 104.938 53.7615 104.513 53.574C103.975 53.3365 103.325 53.1865 102.613 53.1865C101.788 53.1865 101.038 53.324 100.425 53.6115C99.8129 53.899 99.3004 54.2865 98.9004 54.774C98.5004 55.2615 98.2004 55.8365 98.0004 56.499C97.8129 57.149 97.7129 57.8615 97.7129 58.6115C97.7129 59.3615 97.8129 60.0865 98.0129 60.724C98.2129 61.374 98.5379 61.9365 98.9754 62.4115C99.4129 62.8865 99.9754 63.2615 100.638 63.5115C101.3 63.774 102.113 63.899 103.038 63.899C104.95 63.899 105.963 63.4615 106.375 63.2365C106.45 63.199 106.513 63.124 106.425 62.924L105.988 61.7115C105.925 61.5365 105.738 61.599 105.738 61.599C105.263 61.774 104.588 62.0865 103.013 62.0865C101.988 62.0865 101.225 61.7865 100.75 61.3115C100.263 60.824 100.025 60.1115 99.9754 59.1115H106.6C106.6 59.1115 106.775 59.1115 106.788 58.949C106.788 58.874 107.013 57.5865 106.588 56.099L106.625 56.0865ZM100.038 57.474C100.125 56.849 100.3 56.324 100.575 55.9115C100.988 55.2865 101.6 54.949 102.475 54.949C103.35 54.949 103.913 55.2865 104.338 55.9115C104.613 56.324 104.725 56.8615 104.775 57.474H100.05H100.038Z" fill="white"/>
<path d="M88.3496 57.149C88.0746 57.124 87.7371 57.124 87.3121 57.124C86.7496 57.124 86.1996 57.199 85.6746 57.3365C85.1621 57.474 84.6871 57.6865 84.2871 57.974C83.8871 58.2615 83.5621 58.624 83.3246 59.0615C83.0871 59.499 82.9746 60.0115 82.9746 60.5865C82.9746 61.1615 83.0746 61.6865 83.2746 62.099C83.4746 62.5115 83.7621 62.8615 84.1371 63.1365C84.4996 63.399 84.9621 63.599 85.4871 63.724C86.0121 63.8365 86.5996 63.899 87.2371 63.899C87.9121 63.899 88.5996 63.8365 89.2496 63.7365C89.8996 63.624 90.6996 63.4615 90.9121 63.4115C91.1371 63.3615 91.3746 63.299 91.3746 63.299C91.5371 63.2615 91.5246 63.0865 91.5246 63.0865V57.024C91.5246 55.699 91.1621 54.7115 90.4621 54.099C89.7621 53.4865 88.7496 53.1865 87.4246 53.1865C86.9246 53.1865 86.1246 53.249 85.6496 53.349C85.6496 53.349 84.1996 53.624 83.6121 54.099C83.6121 54.099 83.4871 54.174 83.5496 54.3615L84.0246 55.624C84.0871 55.7865 84.2371 55.7365 84.2371 55.7365C84.2371 55.7365 84.2871 55.7115 84.3496 55.6865C85.6246 54.999 87.2371 55.0115 87.2371 55.0115C87.9496 55.0115 88.4996 55.1615 88.8746 55.4365C89.2371 55.7115 89.4246 56.1365 89.4246 57.0115V57.2865C88.8621 57.199 88.3371 57.1615 88.3371 57.1615L88.3496 57.149ZM85.7121 61.799C85.4496 61.5865 85.4121 61.5365 85.3246 61.4115C85.1996 61.2115 85.1246 60.924 85.1246 60.549C85.1246 59.9615 85.3121 59.549 85.7121 59.2615C85.7121 59.2615 86.2871 58.7615 87.6371 58.7865C88.5871 58.7865 89.4371 58.9365 89.4371 58.9365V61.949C89.4371 61.949 88.5996 62.124 87.6496 62.1865C86.2996 62.2615 85.6996 61.799 85.7121 61.799Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M141.779 53.7371C141.829 53.5871 141.729 53.5121 141.679 53.4996C141.567 53.4621 141.004 53.3371 140.567 53.3121C139.729 53.2621 139.267 53.3996 138.854 53.5871C138.442 53.7746 137.992 54.0746 137.729 54.4121V53.5996C137.729 53.4871 137.654 53.3996 137.542 53.3996H135.842C135.729 53.3996 135.654 53.4871 135.654 53.5996V63.4871C135.654 63.5996 135.742 63.6871 135.854 63.6871H137.592C137.704 63.6871 137.792 63.5996 137.792 63.4871V58.5496C137.792 57.8871 137.867 57.2246 138.017 56.8121C138.154 56.3996 138.354 56.0746 138.592 55.8371C138.829 55.5996 139.104 55.4371 139.404 55.3496C139.717 55.2496 140.054 55.2246 140.292 55.2246C140.642 55.2246 141.017 55.3121 141.017 55.3121C141.142 55.3246 141.217 55.2496 141.254 55.1371C141.367 54.8371 141.692 53.9246 141.754 53.7496L141.779 53.7371Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M125.438 49.1625C125.225 49.1 125.038 49.05 124.788 49.0125C124.538 48.9625 124.225 48.9375 123.888 48.9375C122.688 48.9375 121.75 49.275 121.088 49.95C120.425 50.6125 119.975 51.625 119.763 52.95L119.688 53.4H118.188C118.188 53.4 118 53.4 117.963 53.5875L117.713 54.9625C117.7 55.1 117.75 55.175 117.925 55.175H119.388L117.9 63.4625C117.788 64.125 117.65 64.675 117.5 65.1C117.35 65.5125 117.213 65.8125 117.038 66.0375C116.863 66.25 116.713 66.4125 116.438 66.5C116.213 66.575 115.95 66.6125 115.663 66.6125C115.5 66.6125 115.288 66.5875 115.125 66.55C114.963 66.525 114.888 66.4875 114.763 66.4375C114.763 66.4375 114.588 66.375 114.525 66.55C114.475 66.6875 114.075 67.7625 114.038 67.9C113.988 68.025 114.063 68.1375 114.138 68.1625C114.338 68.2375 114.475 68.275 114.738 68.3375C115.1 68.425 115.413 68.425 115.713 68.425C116.325 68.425 116.875 68.3375 117.338 68.175C117.8 68.0125 118.2 67.7125 118.563 67.325C118.95 66.9 119.188 66.45 119.425 65.8375C119.65 65.2375 119.85 64.4875 120.013 63.6125L121.5 55.1625H123.688C123.688 55.1625 123.875 55.1625 123.913 54.975L124.163 53.6C124.175 53.4625 124.125 53.3875 123.95 53.3875H121.838C121.838 53.3875 121.95 52.6 122.188 51.8875C122.288 51.5875 122.488 51.35 122.65 51.175C122.813 51.0125 123 50.9 123.2 50.825C123.413 50.7625 123.65 50.725 123.913 50.725C124.113 50.725 124.313 50.75 124.463 50.775C124.663 50.8125 124.75 50.8375 124.8 50.8625C125.013 50.925 125.05 50.8625 125.088 50.7625L125.6 49.375C125.65 49.225 125.525 49.1625 125.475 49.15L125.438 49.1625Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M95.8543 63.4869C95.8543 63.5994 95.7793 63.6869 95.6668 63.6869H93.9043C93.7918 63.6869 93.7168 63.5994 93.7168 63.4869V49.3494C93.7168 49.2369 93.7918 49.1494 93.9043 49.1494H95.6668C95.7793 49.1494 95.8543 49.2369 95.8543 49.3494V63.4869Z" fill="white"/>
</g>
</g>
<defs>
<filter id="filter0_dd_2491_38778" x="0.978516" y="0" width="227" height="145" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="12"/>
<feGaussianBlur stdDeviation="8"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.624972 0 0 0 0 0.620887 0 0 0 0 0.825163 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2491_38778"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_2491_38778"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.346977 0 0 0 0 0.342936 0 0 0 0 0.553076 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_2491_38778" result="effect2_dropShadow_2491_38778"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2491_38778" result="shape"/>
</filter>
<clipPath id="clip0_2491_38778">
<rect width="115" height="80" fill="white" transform="translate(56.9785 21)"/>
</clipPath>
</defs>
</svg>

    )
}