import React, { useEffect, useState } from "react";
import { Nav } from "./sections/Nav";

const Terms = () => {
  return (
    <div>
      <Nav changeLogoColorOnScroll={false} />
      <div
        style={{
          zIndex: 99,
          display: "flex",
          justifyContent: "center",
          height: "95vh",
        }}
      >
        <div
          style={{
            width: "90%",
            maxWidth: "900px",
            textAlign: "center",
            paddingTop: "80px",
            paddingBottom: "40px",
          }}
        >
          <div style={{ paddingTop: "80px" }}>
            <h1
              style={{
                fontWeight: 600,
                lineHeight: "79.2px",
                textAlign: "center",
                marginBottom: "16px",
              }}
              className="text-[54px] md:text-[72px]"
            >
              Terms of Service
            </h1>
            <p
              style={{
                fontSize: "24px",
                lineHeight: "33.6px",
                paddingBottom: "40px",
                color: "#6C6684"
              }}
            >
              By accessing Qoos, you agree to these terms.
            </p>
            <hr
              style={{
                width: "10%",
                borderTop: "2px solid #565ADD",
                height: "1px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                1. Acceptance of Terms
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                By accessing or using Qoos.ai (the “Service”), you agree to
                comply with and be bound by these Terms of Service. If you do
                not agree to these terms, you must not use the Service.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                2. Description of Service
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                Qoos.ai is a coaching, training, and learning platform that
                provides insights into what top salespeople do to become top
                billers. The Service is delivered through a Chrome Extension and
                integrates with CRMs to sync deal information.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "24px",
                }}
              >
                3. Use of the Service
              </h2>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  3.1. Account Registration
                </h3>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    textAlign: "left",
                  }}
                >
                  You may need to register for an account to access certain
                  Service features. You agree to provide accurate, current, and
                  complete information during the registration process and to
                  update such information to keep it accurate, current, and
                  complete.
                </p>
              </div>
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  textAlign: "left",
                  lineHeight: "21.6px",
                  marginBottom: "8px",
                }}
              >
                3.2. Account Security
              </h3>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                You are responsible for safeguarding your password and any
                activities or actions under your account. You agree to notify us
                immediately of any unauthorized use of your account.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                4. User Conduct
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                You agree not to engage in any of the following prohibited
                activities:
              </p>
              <ul
                style={{
                  textAlign: "left",
                  listStyleType: "disc",
                  marginLeft: "30px",
                  lineHeight: "25.2px",
                }}
              >
                <li>
                  Copying, distributing, or disclosing any part of the Service
                  in any medium.
                </li>
                <li>Using any automated system to access the Service.</li>
                <li>Interfering with the proper working of the Service.</li>
                <li>
                  Engaging in any illegal, harmful, or otherwise objectionable
                  activity.
                </li>
              </ul>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                5. Privacy
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                Our Privacy Policy explains how we collect, use, and disclose
                your information. By using the Service, you agree to our Privacy
                Policy.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                6. Intellectual Property
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                All content, trademarks, service marks, and logos on the Service
                are the property of Qoos Pty Ltd or its licensors. You agree not
                to use any of these without our prior written consent.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "24px",
                }}
              >
                7. Subscription and Fees
              </h2>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  7.1. Subscription Plans
                </h3>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    textAlign: "left",
                  }}
                >
                  The Service is billed on a subscription basis
                  (“Subscription(s)”). You will be billed in advance on a
                  recurring and periodic basis (“Billing Cycle”). Billing cycles
                  are set monthly or annually, depending on the subscription
                  plan you select when purchasing a Subscription.
                </p>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  7.2. Monthly Plan Cancellation
                </h3>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    textAlign: "left",
                  }}
                >
                  You can cancel your subscription anytime if you are on a
                  monthly plan. Your cancellation will take effect at the end of
                  the current billing period, and you will not be charged for
                  the subsequent billing period.
                </p>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  7.3. Annual Plan Cancellation
                </h3>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    textAlign: "left",
                  }}
                >
                  If you are on an annual plan, you must provide us with at
                  least 30 days' notice before your current billing period ends
                  to cancel your subscription. Your cancellation will take
                  effect at the end of the current billing period, and you will
                  not be charged for the subsequent billing period.
                </p>
              </div>
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  textAlign: "left",
                  lineHeight: "21.6px",
                  marginBottom: "8px",
                }}
              >
                7.4. Fee Changes
              </h3>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                Qoos Pty Ltd, at its sole discretion and at any time, may modify
                the subscription fees for the subscriptions. Any Subscription
                fee change will become effective at the end of the then-current
                Billing Cycle. Qoos Pty Ltd will provide you with reasonable
                prior notice of any change in Subscription fees to allow you to
                terminate your Subscription before such change becomes
                effective.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                8. Limitation of Liability
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                In no event shall Qoos Pty Ltd, its directors, employees, or
                agents be liable for any indirect, incidental, special,
                consequential, or punitive damages, including without
                limitation, loss of profits, data, use, goodwill, or other
                intangible losses, resulting from your use of the Service.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                9. Indemnification
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                You agree to defend, indemnify, and hold harmless Qoos Pty Ltd
                and its licensee and licensors, and their employees,
                contractors, agents, officers, and directors, from and against
                any and all claims, damages, obligations, losses, liabilities,
                costs, or debt, and expenses (including but not limited to
                attorney’s fees), resulting from or arising out of a) your use
                and access of the Service, or b) a breach of these Terms.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                10. Governing Law
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                These Terms of Service shall be governed and construed in
                accordance with the laws of New South Wales, Australia, without
                regard to its conflict of law provisions.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                11. Changes to the Terms of Service
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                We reserve the right to modify or replace these Terms of Service
                at any time without notice. What constitutes a material change
                will be determined at our sole discretion.
              </p>
            </div>
            <div>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                12. Contact Information
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                If you have any questions about these Terms of Service, please
                contact us at{" "}
                <a
                  style={{ textDecoration: "underline", color: "blueviolet" }}
                  href="mailto:support@qoos.io"
                >
                  support@qoos.ai
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
