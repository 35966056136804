import React, { useState, useRef } from "react";
import { FormInput } from "../forms/controls/form.input";
import { Button } from "@headlessui/react";
import { validateEmail, validateWorkEmail } from "util/form.util";
import { prospectService } from "service/demo/prospect.service";
import { RequestModal } from "../shared/modals/RequestModal";
import { Spinner } from "components/shared/Spinner";

export const ContactForm = ({
  isModal = false,
  onSubmit = undefined,
  onClose = undefined,
}) => {
  const [formState, setFormState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const onInputChange = (event) => {
    let { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const emailRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formState?.email) {
      emailRef.current.focus();
      return;
    }

    try {
      setIsLoading(true);
      await prospectService.scheduleDemo(formState);
      setFormState(null);
      setSuccessModalOpen(true);
    } catch (err) {
      alert("err");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={
          isModal
            ? "flex flex-col gap-4 bg-[white]"
            : "flex flex-col gap-4 p-6 md:w-[480px] h-fit-content bg-[white] rounded-[16px]"
        }
      >
      <FormInput
        required
        name="email"
        type="email"
        value={formState?.email}
        placeholder="Enter work email..."
        onChange={onInputChange}
        ref={emailRef}
      />
        {!!formState?.email?.length && validateEmail(formState.email) && validateWorkEmail(formState.email) && (
          <>
            <div className="flex gap-2">
              <FormInput
                required
                name="firstName"
                value={formState?.firstName}
                placeholder="Enter your first name"
                onChange={onInputChange}
                className={"w-1/2"}
              />
              <FormInput
                required
                name="lastName"
                value={formState?.lastName}
                placeholder="Enter your last name"
                onChange={onInputChange}
                className={"w-1/2"}
              />
            </div>
            <FormInput
              required
              name="jobTitle"
              value={formState?.jobTitle}
              placeholder="Enter your job title"
              onChange={onInputChange}
            />
            <FormInput
              required
              name="mobile"
              type="tel"
              value={formState?.mobile}
              placeholder="Enter mobile number"
              onChange={onInputChange}
              defaultCountry="AU"
            />
            <FormInput
              required
              name="companyName"
              value={formState?.companyName}
              placeholder="e.g Acme Inc"
              onChange={onInputChange}
            />
          </>
        )}
        <Button
          type="submit"
          disabled={!formState?.email || isLoading}
          className="flex justify-center items-center text-md px-6 py-3 leading-none rounded-lg bg-[#565ADD] text-white font-semibold hover:border-transparent hover:bg-gray-800"
        >
          {isLoading ? <Spinner /> : "Schedule Demo"}
        </Button>
        <div className="text-center text-[12px] md:text-[16px]">
          I agree to the privacy policy, including to Qoos using my email to
          contact me for marketing purposes.
        </div>
      </form>
      <RequestModal
        isOpen={successModalOpen}
        onClose={() => {
          setSuccessModalOpen(false);
          if (onClose) {
            onClose();
          }
        }}
      />
    </>
  );
};
