import React, { useEffect } from "react";
import { Nav } from "./sections/Nav";

const PrivacyPolicy = () => {
  return (
    <div>
      <Nav changeLogoColorOnScroll={false} />
      <div
        style={{
          zIndex: 99,
          display: "flex",
          justifyContent: "center",
          height: "95vh",
        }}
      >
        <div
          style={{
            width: "90%",
            maxWidth: "900px",
            textAlign: "center",
            paddingTop: "80px",
            paddingBottom: "40px",
          }}
        >
          <div style={{ paddingTop: "80px" }}>
            <h1
              style={{
                fontWeight: 600,
                lineHeight: "79.2px",
                textAlign: "center",
                marginBottom: "16px",
              }}
              className="text-[54px] md:text-[72px]"
            >
              Privacy Policy
            </h1>
            <p
              style={{
                fontSize: "24px",
                lineHeight: "33.6px",
                paddingBottom: "40px",
                color: "#6C6684"
              }}
            >
              Learn how we collect, use, and protect your personal information.
              Your privacy is important to us.
            </p>
            <hr
              style={{
                width: "10%",
                borderTop: "2px solid #565ADD",
                height: "1px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
          </div>
          <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  paddingBottom: "8px",
                }}
              >
                Introduction
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                Qoos Pty Ltd (“we”, “our”, “us”) operates the website Qoos.ai
                and provides a coaching, training, and learning platform through
                a Chrome Extension, integrating with CRMs for syncing deal
                information. This Privacy Policy explains how we collect, use,
                disclose, and protect your information.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "24px",
                }}
              >
                1. Information We Collect
              </h2>
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  textAlign: "left",
                  lineHeight: "21.6px",
                  marginBottom: "8px",
                }}
              >
                1.1. Information You Provide
              </h3>
              <ul
                style={{
                  textAlign: "left",
                  listStyleType: "disc",
                  marginLeft: "30px",
                  marginBottom: "24px",
                  lineHeight: "25.2px",
                }}
              >
                <li>
                  <span style={{ fontWeight: 600 }}>Account Information:</span>{" "}
                  When you register for an account, we collect information such
                  as your name, email address, and other contact details.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>Billing Information:</span>{" "}
                  If you purchase a subscription, we collect billing details,
                  including payment method information.
                </li>
              </ul>
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  textAlign: "left",
                  lineHeight: "21.6px",
                  marginBottom: "8px",
                }}
              >
                1.2. Information We Collect Automatically
              </h3>
              <ul
                style={{
                  textAlign: "left",
                  listStyleType: "disc",
                  marginLeft: "30px",
                  marginBottom: "24px",
                  lineHeight: "25.2px",
                }}
              >
                <li>
                  <span style={{ fontWeight: 600 }}>Usage Data:</span> We
                  collect information about how you interact with our Service,
                  such as the features you use and the time spent on the
                  platform.
                </li>
                <li>
                  <span style={{ fontWeight: 600 }}>Device Information:</span>{" "}
                  We collect information about the device you use to access the
                  Service, including IP address, browser type, and operating
                  system.
                </li>
              </ul>
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  textAlign: "left",
                  lineHeight: "21.6px",
                  marginBottom: "8px",
                }}
              >
                1.3. Information from Third Parties
              </h3>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                We may receive information about you from third-party services
                you connect to Qoos.ai, such as CRMs, to enhance your
                experience.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "24px",
                }}
              >
                2. How We Use Your Information
              </h2>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  2.1. To Provide and Improve Our Service
                </h3>
                <ul
                  style={{
                    textAlign: "left",
                    listStyleType: "disc",
                    marginLeft: "30px",
                    lineHeight: "25.2px",
                  }}
                >
                  <li>To operate and maintain the Service.</li>
                  <li>To process transactions and send related information.</li>
                  <li>To develop new features, products, and services.</li>
                </ul>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  2.2. To Communicate with You
                </h3>
                <ul
                  style={{
                    textAlign: "left",
                    listStyleType: "disc",
                    marginLeft: "30px",
                    lineHeight: "25.2px",
                  }}
                >
                  <li>
                    To send you updates, notifications, and other information
                    related to your account.
                  </li>
                  <li>
                    To provide customer support and respond to your inquiries.
                  </li>
                </ul>
              </div>
              <div>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  2.3. For Security and Compliance
                </h3>
                <ul
                  style={{
                    textAlign: "left",
                    listStyleType: "disc",
                    marginLeft: "30px",
                  }}
                >
                  <li>
                    To monitor and analyze usage and trends to improve user
                    experience.
                  </li>
                  <li>
                    To prevent fraudulent activities and enhance the security of
                    the Service.
                  </li>
                  <li>
                    To comply with legal obligations and enforce our Terms of
                    Service.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "24px",
                }}
              >
                3. How We Share Your Information
              </h2>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  3.1. With Service Providers
                </h3>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    textAlign: "left",
                  }}
                >
                  We may share your information with third-party service
                  providers who perform services on our behalf, such as payment
                  processing, data analysis, and customer service.
                </p>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  3.2. For Legal Reasons
                </h3>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    textAlign: "left",
                  }}
                >
                  We may disclose your information if required to do so by law
                  or in response to valid requests by public authorities.
                </p>
              </div>
              <div>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    textAlign: "left",
                    lineHeight: "21.6px",
                    marginBottom: "8px",
                  }}
                >
                  3.3. Business Transfers
                </h3>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    lineHeight: "25.2px",
                    textAlign: "left",
                  }}
                >
                  If we are involved in a merger, acquisition, or asset sale,
                  your information may be transferred as part of that
                  transaction.
                </p>
              </div>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                4. Security
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                We take reasonable measures to protect your information from
                unauthorized access, use, or disclosure. However, no internet or
                email transmission is ever fully secure or error-free.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                5. Data Retention
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                We will retain your information as long as your account is
                active or as needed to provide you with the Service. We will
                also retain and use your information to comply with our legal
                obligations, resolve disputes, and enforce our agreements.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                6. Your Rights
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                You can access, correct, or delete your personal information by
                contacting us at support@qoos.ai. We will respond to your
                request within a reasonable timeframe.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                7. International Data Transfers
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                Your information may be transferred to and maintained on
                computers outside your state, province, country, or other
                governmental jurisdiction where the data protection laws may
                differ from those of your jurisdiction.
              </p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                8. Changes to This Privacy Policy
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                We reserve the right to update or change our Privacy Policy at
                any time. We will notify you of any changes by posting the new
                Policy on this page. You are advised to review this Policy
                periodically for any changes.
              </p>
            </div>
            <div>
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "28.8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                9. Contact Us
              </h2>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "25.2px",
                  textAlign: "left",
                }}
              >
                If you have any questions about this Privacy Policy, please
                contact us at{" "}
                <a
                  style={{ textDecoration: "underline", color: "blueviolet" }}
                  href="mailto:support@qoos.io"
                >
                  support@qoos.ai
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
