import React from "react";
import { QoosLogo } from "../../components/shared/logos/QoosLogo";
import { useMediaQuery } from "react-responsive";
import { ChromeLogo } from "../../components/shared/logos/ChromeLogo";

export const Download = () => {
  const isMdScreen = useMediaQuery({ minWidth: 768 }); // Tailwind's 'md' breakpoint

  return (
    <div className="bg-[#F9F9FF] flex flex-col gap-5 md:gap-20 pt-10 md:py-20">
      <div className="flex flex-col items-center gap-2">
        <div className="flex flex-row justify-center">
          <QoosLogo fill="#565ADD" width={isMdScreen ? "250" : "130"} />
        </div>
        <h1 className="text-[24px] lg:text-[48px] font-semibold text-[#2B1C50] md:leading-[80px] tracking-tight md:tracking-normal">
          what the <span style={{ color: "#565ADD" }}>best</span> sellers{"   "}
          <span style={{ color: "#565ADD" }}>do</span>
        </h1>

        <div className="flex flex-row justify-center items-center text-md px-6 py-3 leading-none rounded-lg bg-[#565ADD] text-white font-semibold gap-2">
          <ChromeLogo fill="white" />
          <a
            href="https://chromewebstore.google.com/detail/pnihaepphikdmamllajpcjaoaalolpeo?hl=en-GB&authuser=0"
            target="_blank"
            className="text-[white] text-2xl tracking-tight md:tracking-normal"
          >
            Add to Chrome
          </a>
        </div>
      </div>
      <div className="flex justify-center">
        {isMdScreen && <img src="/images/Download/Laptop.png" alt="Laptop" />}
        {!isMdScreen && (
          <img src="/images/Download/Laptop-Transparent.png" alt="Laptop" />
        )}
      </div>
    </div>
  );
};
