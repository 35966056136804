import React from "react";
import { LogoCarousel } from "../../components/animations/PartnerCarousel";

export const Partners = () => {
  return (
    <div className="w-screen bg-[#F9F9FF] py-5 md:py-20 flex flex-col items-center overflow-x-hidden">
      <div className="text-center w-full md:w-auto">
        <h1 className="text-[25px] md:text-[35px] font-semibold whitespace-normal tracking-tight md:tracking-normal">
          Partnering with <br className="md:hidden" />
          world-class companies
        </h1>
      </div>

      <img
        src="/images/Partners/logos.png"
        className="w-screen max-w-layout-content hidden md:block"
      />

      <div className="w-full md:w-[80%] mt-5 md:mt-10 md:hidden">
        <LogoCarousel />
      </div>
    </div>
  );
};
