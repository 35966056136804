const env =
  process.env.REACT_APP_ENV || process.env.ENVIRONMENT || process.env.NODE_ENV;
const isProd =
  env && (env.toLowerCase() === "prod" || env.toLowerCase() === "production");

let companyApiUrl = "http://localhost:3100";
let instance = "local";

if (isProd) {
  companyApiUrl = "https://api.qoos.ai";
  instance = "prod";
}

const config = {
  companyApiUrl,
  instance,
};

export default config;
