export const ServiceNow = () => {
    return (
<svg width="292" height="153" viewBox="0 0 292 153" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dd_2540_18745)">
<rect x="16" y="5" width="260" height="120" rx="16" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.227 60.668C103.645 60.6643 102.112 61.2164 100.895 62.228V60.8264H96.9434V76.2188H101.053V66.3788C101.495 65.8045 102.059 65.3356 102.704 65.006C103.349 64.6764 104.06 64.4944 104.784 64.4732C105.345 64.4444 105.905 64.5284 106.433 64.7204V60.7736C106.035 60.7037 105.631 60.6684 105.227 60.668Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M69.3924 71.5222C70.566 72.5144 72.0556 73.0545 73.5924 73.045C74.6988 73.045 75.552 72.505 75.552 71.7442C75.552 69.427 68.1228 70.2526 68.1228 65.3014C68.1228 62.3506 70.968 60.5098 74.0028 60.5098C75.8457 60.5148 77.6472 61.0569 79.1868 62.0698L77.2644 65.047C76.4159 64.4031 75.3889 64.0376 74.3244 64.0006C73.1868 64.0006 72.2376 64.4446 72.2376 65.2378C72.2376 67.237 79.668 66.4438 79.668 71.7754C79.668 74.7274 76.788 76.5358 73.566 76.5358C71.3459 76.5142 69.1888 75.7946 67.4004 74.479L69.3924 71.5222Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M95.1878 68.3794C95.1878 64.0954 92.1878 60.5098 87.947 60.5098C83.3942 60.5098 80.4854 64.2538 80.4854 68.539C80.4402 69.6043 80.6174 70.6675 81.0057 71.6606C81.394 72.6537 81.9848 73.5552 82.7406 74.3074C83.4963 75.0597 84.4005 75.6463 85.3954 76.03C86.3903 76.4136 87.4542 76.5859 88.5194 76.5358C89.7043 76.5514 90.8789 76.3131 91.9641 75.8371C93.0493 75.361 94.02 74.6582 94.811 73.7758L92.471 71.4274C91.981 71.9681 91.3867 72.4042 90.724 72.7096C90.0612 73.015 89.3436 73.1833 88.6142 73.2046C87.5893 73.2379 86.5902 72.8795 85.8202 72.2022C85.0502 71.525 84.5671 70.5798 84.4694 69.559H95.093C95.1584 69.1692 95.1901 68.7746 95.1878 68.3794ZM84.659 66.5398C84.8065 65.7773 85.2158 65.0903 85.8163 64.5977C86.4167 64.1051 87.1704 63.8378 87.947 63.8422C88.6952 63.8433 89.4172 64.1183 89.9765 64.6153C90.5358 65.1123 90.8939 65.7969 90.983 66.5398H84.659Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M116.227 70.2207L120.432 60.8271H124.702L117.65 76.2196H114.805L107.754 60.8271H112.022L116.227 70.2207Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M128.155 53.6C128.675 53.6095 129.18 53.7723 129.608 54.0681C130.036 54.3639 130.367 54.7794 130.559 55.2626C130.752 55.7457 130.797 56.275 130.69 56.7839C130.583 57.2928 130.328 57.7587 129.957 58.1231C129.586 58.4875 129.115 58.7342 128.604 58.8323C128.094 58.9303 127.565 58.8753 127.086 58.6742C126.606 58.473 126.197 58.1347 125.909 57.7016C125.621 57.2686 125.467 56.7601 125.467 56.24C125.467 55.8893 125.537 55.542 125.672 55.2186C125.808 54.8951 126.007 54.6019 126.257 54.3561C126.507 54.1103 126.804 53.9169 127.13 53.7871C127.456 53.6572 127.804 53.5937 128.155 53.6Z" fill="black"/>
<path d="M130.206 60.8271H126.096V76.2196H130.206V60.8271Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M147.201 73.0767C146.422 74.1893 145.377 75.089 144.161 75.6934C142.946 76.2978 141.597 76.5876 140.241 76.5363C139.17 76.5634 138.105 76.3757 137.108 75.9842C136.111 75.5927 135.202 75.0054 134.436 74.2569C133.67 73.5085 133.061 72.614 132.647 71.6265C132.232 70.639 132.019 69.5783 132.021 68.5072C132.024 67.4361 132.24 66.3763 132.659 65.3904C133.077 64.4045 133.689 63.5125 134.458 62.767C135.227 62.0215 136.138 61.4377 137.137 61.0501C138.135 60.6625 139.201 60.479 140.272 60.5103C141.51 60.501 142.734 60.7733 143.851 61.3065C144.969 61.8398 145.95 62.62 146.722 63.5883L143.813 66.1587C143.407 65.5967 142.876 65.1376 142.261 64.8179C141.646 64.4983 140.965 64.327 140.272 64.3179C139.721 64.3156 139.175 64.4239 138.667 64.6364C138.158 64.8488 137.698 65.1611 137.312 65.5547C136.926 65.9483 136.624 66.4153 136.422 66.928C136.22 67.4407 136.123 67.9886 136.137 68.5395C136.118 69.0986 136.216 69.6554 136.423 70.175C136.63 70.6947 136.942 71.1659 137.34 71.5591C137.738 71.9524 138.213 72.2591 138.735 72.4602C139.257 72.6612 139.815 72.7522 140.374 72.7275C141.104 72.7129 141.82 72.5204 142.459 72.1666C143.098 71.8127 143.641 71.3083 144.041 70.6971L147.201 73.0767Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M162.132 73.7748C161.341 74.6572 160.37 75.3601 159.285 75.8362C158.2 76.3123 157.025 76.5505 155.84 76.5348C154.775 76.5844 153.712 76.4117 152.717 76.0279C151.723 75.644 150.819 75.0573 150.064 74.3051C149.308 73.5528 148.718 72.6516 148.33 71.6587C147.942 70.6659 147.764 69.6031 147.81 68.538C147.81 64.2528 150.718 60.5088 155.271 60.5088C159.507 60.5088 162.511 64.0944 162.511 68.3784C162.513 68.772 162.482 69.1651 162.416 69.5532H151.792C151.889 70.5742 152.372 71.52 153.141 72.1979C153.911 72.8759 154.91 73.2351 155.935 73.2024C156.664 73.181 157.382 73.0125 158.045 72.7072C158.708 72.4018 159.302 71.9658 159.793 71.4252L162.132 73.7748ZM158.306 66.54C158.217 65.7972 157.859 65.1126 157.3 64.6156C156.741 64.1186 156.019 63.8435 155.271 63.8424C154.495 63.8378 153.741 64.105 153.14 64.5976C152.54 65.0903 152.13 65.7774 151.983 66.54H158.306Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M164.143 76.219V60.8266H168.094V62.0638C169.311 61.0546 170.844 60.5046 172.425 60.5098C173.438 60.507 174.439 60.7273 175.357 61.1552C176.275 61.5831 177.088 62.2079 177.737 62.9854C178.92 64.5251 179.483 66.4513 179.318 68.3854V76.219H175.208V68.059C175.265 67.5402 175.214 67.0151 175.056 66.5176C174.898 66.02 174.637 65.5612 174.291 65.1706C173.665 64.5914 172.836 64.2834 171.983 64.3138C171.259 64.3352 170.549 64.5171 169.904 64.8464C169.259 65.1758 168.695 65.6443 168.253 66.2182V76.219H164.143Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M189.904 60.5102C188.1 60.5064 186.337 61.0445 184.844 62.0546C183.35 63.0647 182.194 64.5002 181.525 66.1751C180.857 67.85 180.708 69.6871 181.096 71.4481C181.484 73.2091 182.393 74.8128 183.703 76.0514C184.011 76.3441 184.413 76.5172 184.837 76.5392C185.26 76.5612 185.678 76.4307 186.014 76.1714C187.123 75.3462 188.469 74.9006 189.851 74.9006C191.233 74.9006 192.578 75.3462 193.687 76.1714C194.026 76.4313 194.448 76.5606 194.875 76.5357C195.301 76.5108 195.705 76.3334 196.012 76.0358C197.312 74.8018 198.214 73.2076 198.603 71.4575C198.991 69.7074 198.848 67.8812 198.192 66.2129C197.536 64.5447 196.396 63.1104 194.919 62.0939C193.443 61.0775 191.696 60.5252 189.904 60.5078M189.851 73.9994C189.258 74.0154 188.668 73.9105 188.118 73.6911C187.567 73.4717 187.066 73.1423 186.647 72.7231C186.228 72.3039 185.898 71.8037 185.679 71.253C185.459 70.7022 185.354 70.1125 185.37 69.5198C185.37 68.3314 185.842 67.1917 186.682 66.3514C187.523 65.5111 188.662 65.039 189.851 65.039C191.039 65.039 192.179 65.5111 193.019 66.3514C193.859 67.1917 194.332 68.3314 194.332 69.5198C194.347 70.1125 194.242 70.7022 194.023 71.253C193.803 71.8037 193.474 72.3039 193.054 72.7231C192.635 73.1423 192.135 73.4717 191.584 73.6911C191.033 73.9105 190.443 74.0154 189.851 73.9994Z" fill="#62D84E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M206.945 76.2196H203.884L197.779 60.8271H201.883L205.23 69.6231L208.512 60.8271H211.942L215.194 69.6231L218.569 60.8271H222.674L216.57 76.2196H213.509L210.227 67.4547L206.945 76.2196Z" fill="black"/>
<path d="M222.722 74.4793H222.375V75.1309H222.08V73.3477H222.8C222.876 73.3473 222.95 73.362 223.02 73.3907C223.09 73.4194 223.153 73.4616 223.206 73.515C223.26 73.5683 223.302 73.6316 223.331 73.7014C223.359 73.7711 223.374 73.8458 223.374 73.9213C223.373 74.0331 223.339 74.1421 223.276 74.2344C223.213 74.3267 223.124 74.3982 223.02 74.4397L223.425 75.1345H223.104L222.722 74.4793ZM222.375 74.2165H222.8C222.88 74.2165 222.956 74.1849 223.012 74.1286C223.068 74.0723 223.1 73.996 223.1 73.9165C223.1 73.8369 223.068 73.7606 223.012 73.7043C222.956 73.6481 222.88 73.6165 222.8 73.6165H222.379L222.375 74.2165Z" fill="black"/>
<path d="M222.68 72.6171C223.007 72.6168 223.327 72.7137 223.599 72.8954C223.872 73.0771 224.084 73.3354 224.209 73.6378C224.335 73.9401 224.368 74.2729 224.304 74.594C224.24 74.915 224.083 75.21 223.851 75.4416C223.62 75.6731 223.325 75.8308 223.004 75.8948C222.683 75.9587 222.35 75.926 222.048 75.8008C221.746 75.6756 221.487 75.4635 221.305 75.1914C221.123 74.9192 221.026 74.5992 221.026 74.2719C221.027 73.8334 221.201 73.413 221.511 73.1029C221.821 72.7927 222.241 72.618 222.68 72.6171ZM222.68 72.3555C222.3 72.3555 221.929 72.4681 221.613 72.679C221.297 72.89 221.051 73.1899 220.906 73.5407C220.761 73.8916 220.723 74.2776 220.797 74.65C220.871 75.0225 221.054 75.3646 221.322 75.6331C221.591 75.9016 221.933 76.0845 222.305 76.1586C222.678 76.2327 223.064 76.1946 223.415 76.0493C223.765 75.904 224.065 75.6579 224.276 75.3422C224.487 75.0264 224.6 74.6552 224.6 74.2755C224.6 73.7663 224.397 73.2779 224.037 72.9178C223.677 72.5578 223.189 72.3555 222.68 72.3555Z" fill="black"/>
</g>
<defs>
<filter id="filter0_dd_2540_18745" x="0" y="0" width="292" height="153" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="12"/>
<feGaussianBlur stdDeviation="8"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.624972 0 0 0 0 0.620887 0 0 0 0 0.825163 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2540_18745"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_2540_18745"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.346977 0 0 0 0 0.342936 0 0 0 0 0.553076 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_2540_18745" result="effect2_dropShadow_2540_18745"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2540_18745" result="shape"/>
</filter>
</defs>
</svg>
    )
}