export const Divider = ({ show = true }) => {
  return (
    <>
      {show ? (
        <div
          className={`border-b-[1px] border-[#565ADD] h-[1px] w-[80px]`}
        ></div>
      ) : (
        <></>
      )}
    </>
  );
};
