import React from 'react';

const BullhornDashboard = () => {
  const styles = {
    container: {
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  };

  return (
    <div style={styles.container}>
      <img
        src="/images/Static/Dashboard/bullhorn.png"
        alt="Full Screen"
        style={{
            width: '100vw',
            objectFit: 'cover',
          }}
      />
    </div>
  );
};

export default BullhornDashboard;