import axios from "axios";
import config from "../configs/index";

export class BaseService {
  client;
  isPublicRoute;

  constructor(baseUrl) {
    this.client = axios.create({
      baseURL: baseUrl ?? config.companyApiUrl,
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });
  }
}
