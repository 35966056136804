import React from "react";
import { Contact } from "./sections/Contact";
import { Content } from "./sections/Content";
import { Integrations } from "./sections/Integrations";
import { Download } from "./sections/Download";
import { Intro } from "./sections/Intro";
import { Main } from "./sections/Main";
import { Nav } from "./sections/Nav";
import { Partners } from "./sections/Partners";

export const HomePage = () => {
  return (
    <div className="overflow-x-hidden">
      <Nav changeLogoColorOnScroll={true} />
      <Main />
      <Content />
      <Intro />
      <Download />
      <Integrations />
      <Partners />
      <Contact />
    </div>
  );
};
