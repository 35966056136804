import React, { useState, forwardRef } from "react";
import { validateEmail, validateUrl, validateWorkEmail } from "../../../util/form.util";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const FormInputComponent = (
  {
    name,
    required,
    placeholder,
    onChange,
    value,
    type = "text",
    className = "",
    ...props
  },
  ref
) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const shouldValidate = type === "email" || type === "url";

  const onChangeValidate = (e) => {
    if (shouldValidate) {
      const val = e.target.value;
      let isValid = true;
      if (type === "email") {
        isValid = !!validateEmail(val);
        !isValid && setErrorMessage("Invalid email address");
        isValid = !!validateWorkEmail(val);
        !isValid && setErrorMessage("Please enter a work email address");
      } else if (type === "url") {
        isValid = !!validateUrl(val);
        setErrorMessage("Invalid URL");
      }
      if (!isValid) {
        setIsInvalid(true);
        if (onChange) onChange(e);
        return;
      }
      setIsInvalid(false);
      setErrorMessage("");
    }
    if (onChange) onChange(e);
  };

  const onPhoneChange = (value) => {
    if (onChange) {
      onChange({ target: { name, value } });
    }
  };

  if (type === "tel") {
    return (
      <>
        <PhoneInput
          {...props}
          required={required}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onPhoneChange}
          className={`${className} border-2 border-gray-300 bg-white h-[42px] px-2 pr-2 rounded-lg text-sm focus:outline-none`}
        />
      </>
    );
  }

  return (
    <>
      <input
        {...props}
        ref={ref}
        required={required}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onInput={onChangeValidate}
        maxLength={100}
        className={`${className} border-2 border-gray-300 bg-white h-[42px] px-2 pr-2 rounded-lg text-sm focus:outline-none ${
          isInvalid ? "border-red-500" : ""
        }`}
      />
      {isInvalid && <p className="text-red-500 text-xs">{errorMessage}</p>}
    </>
  );
};

export const FormInput = forwardRef(FormInputComponent);