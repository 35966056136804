export const HubspotLogo = () => {
  return (
    <svg
      width="225"
      height="145"
      viewBox="0 0 225 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_2491_38741)">
        <rect x="16" y="5" width="192.888" height="112" rx="16" fill="white" />
        <path
          d="M68.2257 63.1107H59.9364V71.8428H56V50.2969H59.9364V59.3236H68.2257V50.2969H72.1613V71.8428H68.2257V63.1107Z"
          fill="#213343"
        />
        <path
          d="M84.7562 64.8787C84.7562 66.6732 83.2945 68.1333 81.5004 68.1333C79.7058 68.1333 78.245 66.6732 78.245 64.8787V55.6504H74.5176V64.8787C74.5176 68.7288 77.6501 71.8605 81.5004 71.8605C85.3499 71.8605 88.4824 68.7288 88.4824 64.8787V55.6504H84.7562V64.8787Z"
          fill="#213343"
        />
        <path
          d="M112.415 56.6006C112.415 54.7085 113.667 54.1085 115.038 54.1085C116.142 54.1085 117.603 54.9487 118.556 55.9697L121.001 53.0883C119.779 51.4379 117.305 50.2969 115.277 50.2969C111.221 50.2969 108.299 52.6683 108.299 56.6006C108.299 63.894 117.215 61.5822 117.215 65.6652C117.215 66.9244 115.993 68.036 114.592 68.036C112.385 68.036 111.669 66.9554 110.655 65.8147L107.941 68.6356C109.672 70.7668 111.818 71.8481 114.383 71.8481C118.229 71.8481 121.33 69.4467 121.33 65.6942C121.33 57.5904 112.415 60.112 112.415 56.6006Z"
          fill="#213343"
        />
        <path
          d="M167.575 68.4013C165.369 68.4013 164.743 67.4477 164.743 65.986V59.5159H168.171V56.2363H164.743V51.9121L160.957 53.6115V66.7913C160.957 70.1611 163.282 71.861 166.471 71.861C166.948 71.861 167.605 71.8302 167.963 71.7418L168.888 68.3428C168.47 68.3716 167.993 68.4013 167.575 68.4013Z"
          fill="#213343"
        />
        <path
          d="M98.965 55.7586C97.1161 55.7586 95.8256 56.2953 94.5792 57.5186V50.4258H90.8398V63.5709C90.8398 68.4911 94.397 71.8608 98.394 71.8608C102.837 71.8608 106.73 68.4311 106.73 63.81C106.73 59.2475 103.14 55.7586 98.965 55.7586ZM98.9418 68.0974C96.5992 68.0974 94.7005 66.1986 94.7005 63.8562C94.7005 61.5139 96.5992 59.6151 98.9418 59.6151C101.284 59.6151 103.183 61.5139 103.183 63.8562C103.183 66.1986 101.284 68.0974 98.9418 68.0974Z"
          fill="#213343"
        />
        <path
          d="M139.562 63.6162C139.562 58.9951 135.669 55.5654 131.226 55.5654C127.229 55.5654 123.672 58.9352 123.672 63.8553V77.0005H127.411V69.9076C128.658 71.131 129.948 71.6676 131.797 71.6676C135.972 71.6676 139.562 68.1788 139.562 63.6162ZM136.015 63.57C136.015 65.9123 134.116 67.8112 131.774 67.8112C129.431 67.8112 127.533 65.9123 127.533 63.57C127.533 61.2276 129.431 59.3288 131.774 59.3288C134.116 59.3288 136.015 61.2276 136.015 63.57Z"
          fill="#213343"
        />
        <path
          d="M151.644 55.3839V51.6277C152.624 51.1645 153.311 50.1727 153.311 49.0215V48.9347C153.311 47.3462 152.011 46.0465 150.423 46.0465H150.336C148.747 46.0465 147.448 47.3462 147.448 48.9347V49.0215C147.448 50.1727 148.135 51.1647 149.115 51.6278V55.3839C147.655 55.6094 146.321 56.2115 145.221 57.0975L134.908 49.075C134.976 48.8137 135.023 48.5446 135.024 48.2621C135.026 46.4628 133.569 45.0023 131.77 45C129.971 44.9978 128.51 46.4545 128.508 48.2538C128.506 50.0531 129.962 51.5136 131.762 51.5159C132.348 51.5166 132.891 51.35 133.367 51.0795L143.511 58.9714C142.649 60.2737 142.143 61.8337 142.143 63.5129C142.143 65.2711 142.699 66.8972 143.637 68.2348L140.552 71.3201C140.308 71.247 140.055 71.1957 139.787 71.1957C138.309 71.1957 137.11 72.3944 137.11 73.8729C137.11 75.3515 138.309 76.5501 139.787 76.5501C141.266 76.5501 142.464 75.3515 142.464 73.8729C142.464 73.6052 142.413 73.352 142.34 73.1081L145.392 70.0563C146.777 71.1138 148.502 71.749 150.38 71.749C154.928 71.749 158.615 68.0616 158.615 63.5129C158.615 59.3953 155.59 55.9934 151.644 55.3839ZM150.38 67.7354C148.047 67.7354 146.157 65.845 146.157 63.5129C146.157 61.1809 148.047 59.2904 150.38 59.2904C152.711 59.2904 154.602 61.1809 154.602 63.5129C154.602 65.845 152.711 67.7354 150.38 67.7354Z"
          fill="#FF5C35"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2491_38741"
          x="0"
          y="0"
          width="224.887"
          height="145"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.624972 0 0 0 0 0.620887 0 0 0 0 0.825163 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2491_38741"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect2_dropShadow_2491_38741"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.346977 0 0 0 0 0.342936 0 0 0 0 0.553076 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2491_38741"
            result="effect2_dropShadow_2491_38741"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2491_38741"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
