import React, { useEffect, useState } from "react";
import { QoosLogo } from "../../components/shared/logos/QoosLogo";
import { useMediaQuery } from "react-responsive";
import { Button } from "@headlessui/react";
import { EmailModal } from "../../components/shared/modals/EmailModal";

export const Nav = ({
  changeLogoColorOnScroll = true,
  staticLogoColor = "#565ADD",
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const isMdScreen = useMediaQuery({ minWidth: 768 }); // Tailwind's 'md' breakpoint
  const smScreenConstraint = useMediaQuery({ maxWidth: 640 }); // Tailwind's 'sm' breakpoint

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoFill = changeLogoColorOnScroll
    ? isScrolled
      ? "#565ADD"
      : "white"
    : staticLogoColor;

  return (
    <nav
      id="navBar"
      className={`fixed top-0 z-50 h-70 flex w-full px-[5%] items-center justify-between p-4 transition-colors duration-300 ${
        isScrolled ? "bg-white shadow" : "bg-transparent"
      }`}
    >
      <div className="flex items-center flex-shrink-0 text-black ">
        <div className="flex ">
          <a href="https://qoos.ai/">
            <QoosLogo fill={logoFill} width={isMdScreen ? undefined : "100"} />
          </a>
        </div>
      </div>

      <div className="flex items-center ">
        {/* {!smScreenConstraint && (
          <div
            class={`${isScrolled ? "text-black" : "text-white"} text-[18px]`}
          >
            <a href="https://app.qoos.ai/auth/login" target="_blank">
              Sign In
            </a>
          </div>
        )} */}

        {isMdScreen && <ScheduleDemoButton />}
        {smScreenConstraint && isScrolled && <ScheduleDemoButton />}
      </div>
    </nav>
  );
};

const ScheduleDemoButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        className="rounded-md bg-[#565ADD] ml-[30px] py-2 px-4 text-[18px] font-medium text-white focus:outline-none data-[hover]:bg-black/30 data-[focus]:outline-1 data-[focus]:outline-white"
      >
        Schedule Demo
      </Button>

      <EmailModal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
        handleClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};
