import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";

export const RequestModal = ({ isOpen, onClose, onSubmit = undefined }) => {
  const handleSubmit = () => {
    window.open("https://app.qoos.ai/calculator/setup", "_blank");

    if (onSubmit) {
      onSubmit();
    }

    onClose();
  };

  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className="relative z-10 focus:outline-none shadow-xxl"
        onClose={onClose}
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 transform-[scale(95%)]"
              enterTo="opacity-100 transform-[scale(100%)]"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 transform-[scale(100%)]"
              leaveTo="opacity-0 transform-[scale(95%)]"
            >
              <DialogPanel className="flex flex-col justify-center items-center w-full max-w-[720px] rounded-xl bg-white px-10 py-6 shadow-2xl">
                <div className="flex items-center bg-[#F0F0FF] h-[24px] rounded-full text-[#565ADD] px-2 py-1 mb-4">
                  success
                </div>
                <DialogTitle
                  as="h3"
                  className="text-base/7 font-bold text-[20px] md:text-[32px] md:leading-8 text-center"
                >
                  WE GOT YOUR REQUEST
                </DialogTitle>
                <p className="mt-4 text-[18px] font-normal text-center">
                  We’ll be in touch shortly. In the meantime, feel free to check
                  out our ROI calculator to see the health of your current sales
                  team and how Qoos can help you smash your sales quota.
                </p>
                <div className="mt-4">
                  <Button
                    className="inline-flex items-center gap-2 rounded-md bg-[#565ADD] py-3 px-5 text-[20px] font-semibold text-white focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                    onClick={handleSubmit}
                  >
                    QoosCalc
                  </Button>
                </div>
                <div
                  className="text-blue-500 mt-5 font-semibold underline cursor-pointer"
                  onClick={onClose}
                >
                  Got it
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
