import React from "react";
import {
  QoosLogo,
  QoosLogoForInlineText,
} from "../../components/shared/logos/QoosLogo";
import { useMediaQuery } from "react-responsive";
import { Divider } from "../../components/shared/Divider";

export const Intro = () => {
  const isMdScreen = useMediaQuery({ minWidth: 768 }); // Tailwind's 'md' breakpoint
  const isLgScreen = useMediaQuery({ minWidth: 1024 }); // Tailwind's 'lg' breakpoint

  const classes = {
    title:
      "text-[26px] md:text-[36px] lg:text-[48px] font-semibold text-[#2B1C50] leading-[30px] md:leading-[60px] tracking-tighter md:tracking-normal",
  };

  return (
    <div className="pb-10 md:pt-20 flex flex-col items-center gap-6 md:gap-0">
      <div className="flex flex-col items-center gap-1 md:mb-10">
        <div className="flex flex-row justify-center items-center gap-2 pt-10 pb-3 md:pb-5">
          <h1 className="text-3xl md:text-[36px] lg:text-[48px] font-semibold text-[#2B1C50] tracking-tighter md:tracking-normal">
            Introducing
          </h1>
          <QoosLogo fill="#565ADD" width={isMdScreen ? "140" : "90"} />
        </div>
        <h1 className="text-[20px] md:text-[40px] font-medium text-center pb-3 md:pb-5 max-w-[85%] lg:max-w-[70%] tracking-tight md:tracking-normal">
          <div className=" justify-end items-end m:justify-start md:items-start inline-block pr-[1px]">
            <QoosLogoForInlineText fill="black" width={isMdScreen ? "27" : "14"} />
          </div>
          oos converts your training content into bite-sized actions delivered
          directly in the flow of work.
        </h1>
        <Divider />
      </div>

      <RightImageContainer
        text={
          <h1 className={classes.title}>
            Embedded directly
            <br />
            in your <span style={{ color: "#565ADD" }}>CRM</span>
          </h1>
        }
        image={<img src="/images/Intro/UI.png" alt="CRM Graphic" />}
      />

      <LeftImageContainer
        text={
          <h1 className={classes.title}>
            Based on a <span style={{ color: "#565ADD" }}>simple</span>
            <br />
            <span style={{ color: "#565ADD" }}>qualification</span> framework
          </h1>
        }
        image={
          <img
            src="/images/Intro/Qualification.png"
            alt="Qualification Graphic"
          />
        }
      />

      <RightImageContainer
        text={
          <h1 className={classes.title}>
            Delivered through
            <br />
            <span style={{ color: "#565ADD" }}>bite-sized prompts</span>
          </h1>
        }
        image={<img src="/images/Intro/Prompts.png" alt="Prompts Graphic" />}
      />

      <LeftImageContainer
        text={
          <h1 className={classes.title}>
            Supported by interactive
            <br />
            <span style={{ color: "#565ADD" }}>AI Coaching</span>
          </h1>
        }
        image={
          <img src="/images/Intro/Coaching.png" alt="AI Coaching Graphic" />
        }
      />
    </div>
  );
};

const RightImageContainer = ({ text, image }) => {
  return (
    <div className="md:w-[1300px] container md:h-fit mx-auto flex flex-col justify-center items-center p-4 md:space-y-0 md:flex-row md:content-center gap-6 md:gap-4">
      <div className="md:w-1/2 space-y-6 mt-18 md:mt-0">
        <div className="md:text-left text-center ">{text}</div>
      </div>
      <div className="flex flex-col md:w-1/2 items-center md:items-end space-y-6">
        {image}
      </div>
    </div>
  );
};

const LeftImageContainer = ({ text, image }) => {
  return (
    <div className="max-w-[1280px] container md:h-fit mx-auto flex flex-col-reverse justify-center items-center p-4 md:space-y-0 md:flex-row md:content-center gap-6 md:gap-4">
      <div className="flex flex-col md:w-1/2 items-center md:items-start space-y-6">
        {image}
      </div>
      <div className="md:w-1/2 space-y-6 mt-18 md:mt-0 flex justify-end">
        <div className="md:text-left text-center md:pr-12">{text}</div>
      </div>
    </div>
  );
};
