import React from "react";

export const QoosLogo = ({ fill = "white", width = undefined }) => {
  const w = width ?? "104";
  const h = width ? +width * 0.3 : "34";

  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 104 34"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9996 16C31.9996 20.5952 30.0624 24.738 26.9602 27.6562L31.3039 32H15.652V31.9963C6.97614 31.8113 0 24.7203 0 16C0 7.16344 7.16335 0 15.9998 0C24.8362 0 31.9996 7.16344 31.9996 16ZM25.7384 16.0002C25.7384 19.673 23.7053 22.871 20.7032 24.5301L21.9124 25.7393H15.9994C10.6207 25.7393 6.26042 21.379 6.26042 16.0002C6.26042 10.6214 10.6207 6.26107 15.9994 6.26107C21.3781 6.26107 25.7384 10.6214 25.7384 16.0002Z"
        fill={fill}
      />
      <path
        d="M46.4589 32.0002C44.2477 32.0002 42.2275 31.4952 40.3985 30.4851C38.5968 29.475 37.1499 28.0964 36.0579 26.3493C34.9933 24.5748 34.4609 22.5683 34.4609 20.3297C34.4609 18.0639 34.9933 16.0573 36.0579 14.3102C37.1499 12.563 38.5968 11.1844 40.3985 10.1743C42.2275 9.16422 44.2477 8.65918 46.4589 8.65918C48.6701 8.65918 50.6766 9.16422 52.4784 10.1743C54.2801 11.1844 55.7133 12.563 56.778 14.3102C57.87 16.0573 58.4159 18.0639 58.4159 20.3297C58.4159 22.5683 57.87 24.5748 56.778 26.3493C55.7133 28.0964 54.2801 29.475 52.4784 30.4851C50.6766 31.4952 48.6701 32.0002 46.4589 32.0002ZM46.4589 26.4721C47.5782 26.4721 48.5473 26.2128 49.3663 25.6941C50.2125 25.1754 50.8677 24.4519 51.3318 23.5238C51.8232 22.5956 52.0689 21.5309 52.0689 20.3297C52.0689 19.1285 51.8232 18.0775 51.3318 17.1766C50.8677 16.2484 50.2125 15.525 49.3663 15.0063C48.5473 14.4603 47.5782 14.1873 46.4589 14.1873C45.3396 14.1873 44.3569 14.4603 43.5106 15.0063C42.6643 15.525 41.9955 16.2484 41.5041 17.1766C41.04 18.0775 40.808 19.1285 40.808 20.3297C40.808 21.5309 41.04 22.5956 41.5041 23.5238C41.9955 24.4519 42.6643 25.1754 43.5106 25.6941C44.3569 26.2128 45.3396 26.4721 46.4589 26.4721Z"
        fill={fill}
      />
      <path
        d="M71.9941 32.0002C69.7829 32.0002 67.7627 31.4952 65.9337 30.4851C64.1319 29.475 62.6851 28.0964 61.5931 26.3493C60.5285 24.5748 59.9961 22.5683 59.9961 20.3297C59.9961 18.0639 60.5285 16.0573 61.5931 14.3102C62.6851 12.563 64.1319 11.1844 65.9337 10.1743C67.7627 9.16422 69.7829 8.65918 71.9941 8.65918C74.2053 8.65918 76.2118 9.16422 78.0136 10.1743C79.8153 11.1844 81.2485 12.563 82.3132 14.3102C83.4051 16.0573 83.9511 18.0639 83.9511 20.3297C83.9511 22.5683 83.4051 24.5748 82.3132 26.3493C81.2485 28.0964 79.8153 29.475 78.0136 30.4851C76.2118 31.4952 74.2053 32.0002 71.9941 32.0002ZM71.9941 26.4721C73.1134 26.4721 74.0825 26.2128 74.9015 25.6941C75.7477 25.1754 76.4029 24.4519 76.867 23.5238C77.3584 22.5956 77.6041 21.5309 77.6041 20.3297C77.6041 19.1285 77.3584 18.0775 76.867 17.1766C76.4029 16.2484 75.7477 15.525 74.9015 15.0063C74.0825 14.4603 73.1134 14.1873 71.9941 14.1873C70.8748 14.1873 69.8921 14.4603 69.0458 15.0063C68.1995 15.525 67.5307 16.2484 67.0393 17.1766C66.5752 18.0775 66.3432 19.1285 66.3432 20.3297C66.3432 21.5309 66.5752 22.5956 67.0393 23.5238C67.5307 24.4519 68.1995 25.1754 69.0458 25.6941C69.8921 26.2128 70.8748 26.4721 71.9941 26.4721Z"
        fill={fill}
      />
      <path
        d="M95.1952 32.0002C92.7383 32.0002 90.5953 31.427 88.7663 30.2804C86.9645 29.1065 85.736 27.5368 85.0809 25.5712L89.5852 23.4419C90.1585 24.643 90.9365 25.5849 91.9193 26.2674C92.9021 26.9498 93.994 27.2911 95.1952 27.2911C96.0688 27.2911 96.7376 27.1136 97.2017 26.7587C97.6658 26.4039 97.8978 25.9125 97.8978 25.2846C97.8978 24.957 97.8159 24.684 97.6521 24.4656C97.4883 24.2199 97.2426 24.0015 96.915 23.8104C96.5875 23.6193 96.178 23.4555 95.6866 23.319L91.8784 22.2543C90.0493 21.7356 88.6434 20.903 87.6606 19.7564C86.6779 18.5825 86.1865 17.2039 86.1865 15.6206C86.1865 14.2283 86.5414 13.0135 87.2511 11.9761C87.9609 10.9387 88.9574 10.1334 90.2404 9.56006C91.5235 8.95947 92.9976 8.65918 94.6629 8.65918C96.8468 8.65918 98.7577 9.17787 100.396 10.2153C102.061 11.2253 103.235 12.6586 103.917 14.5149L99.372 16.6443C99.0444 15.7161 98.4438 14.979 97.5702 14.433C96.724 13.8597 95.7548 13.5731 94.6629 13.5731C93.8712 13.5731 93.2433 13.7369 92.7792 14.0645C92.3424 14.3921 92.1241 14.8425 92.1241 15.4158C92.1241 15.7161 92.2059 15.9891 92.3697 16.2348C92.5335 16.4805 92.7929 16.6989 93.1478 16.89C93.53 17.0811 93.994 17.2585 94.54 17.4223L98.1026 18.487C99.9589 19.033 101.378 19.8656 102.361 20.9849C103.344 22.0769 103.835 23.4282 103.835 25.0389C103.835 26.4312 103.467 27.646 102.73 28.6834C102.02 29.7207 101.024 30.5397 99.7405 31.1403C98.4575 31.7136 96.9423 32.0002 95.1952 32.0002Z"
        fill={fill}
      />
    </svg>
  );
};

// export const QoosLogoForInlineText = ({ fill = "white", width = undefined }) => {
//   const w = width ?? "32";
//   const h = width ?? "32";

//   return (
//     <svg
//       width={w}
//       height={h}
//       viewBox="0 0 32 32"
//       fill={fill}
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M31.9996 16C31.9996 20.5952 30.0624 24.738 26.9602 27.6562L31.3039 32H15.652V31.9963C6.97614 31.8113 0 24.7203 0 16C0 7.16344 7.16335 0 15.9998 0C24.8362 0 31.9996 7.16344 31.9996 16ZM28 16C28 20.5255 25.4949 24.4658 21.7958 26.5101L23.2857 28H16C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" />
//     </svg>
//   );
// };


export const QoosLogoForInlineText = ({ fill = "white", width = undefined }) => {
  const w = width ?? "32";
  const h = width ?? "32";

  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={fill ?? "black"} fillRule="evenodd" clipRule="evenodd" d="M31.9996 16C31.9996 20.5952 30.0624 24.738 26.9602 27.6562L31.3039 32H15.652V31.9963C6.97614 31.8113 0 24.7203 0 16C0 7.16344 7.16335 0 15.9998 0C24.8362 0 31.9996 7.16344 31.9996 16ZM27.5 16.25C27.5 20.4926 25.1515 24.1867 21.6836 26.1032L23.0804 27.5H16.25C10.0368 27.5 5 22.4632 5 16.25C5 10.0368 10.0368 5 16.25 5C22.4632 5 27.5 10.0368 27.5 16.25Z"/>
    </svg>
  );
};
