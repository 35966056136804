export const LinkedInLogo = () => {
    return (
        <svg width="238" height="145" viewBox="0 0 238 145" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dd_2540_18731)">
<rect x="16.0215" y="5" width="205" height="112" rx="16" fill="white" shapeRendering="crispEdges"/>
<g clipPath="url(#clip0_2540_18731)">
<path d="M55.9844 49.6963H60.8795V67.5269H69.9417V72.0443H55.9844V49.6963Z" fill="#0A66C2"/>
<path d="M72.1057 56.8913H76.8083V72.0355H72.1057V56.8913ZM74.457 49.3623C74.9965 49.3624 75.5239 49.5225 75.9724 49.8223C76.421 50.1221 76.7706 50.5482 76.977 51.0467C77.1834 51.5452 77.2373 52.0936 77.132 52.6228C77.0267 53.1519 76.7668 53.638 76.3853 54.0194C76.0038 54.4009 75.5177 54.6607 74.9885 54.7659C74.4594 54.8711 73.9109 54.8171 73.4124 54.6106C72.914 54.4041 72.488 54.0544 72.1882 53.6058C71.8885 53.1572 71.7285 52.6298 71.7285 52.0903C71.7285 51.732 71.7991 51.3772 71.9362 51.0462C72.0733 50.7152 72.2743 50.4145 72.5277 50.1611C72.7811 49.9078 73.0819 49.7069 73.4129 49.5698C73.7439 49.4328 74.0987 49.3622 74.457 49.3623Z" fill="#0A66C2"/>
<path d="M96.4785 49.6934H101.181V63.0491L106.511 56.8854H112.276L106.104 63.8966L112.146 72.0428H106.236L101.244 64.5569H101.182V72.0418H96.4795L96.4785 49.6934Z" fill="#0A66C2"/>
<path d="M79.4141 56.8929H83.93V58.9625H83.9942C84.4452 58.1897 85.0977 57.554 85.882 57.1231C86.6662 56.6923 87.5527 56.4825 88.4469 56.5162C93.2156 56.5162 94.0934 59.6537 94.0934 63.7298V72.0347H89.3908V64.6717C89.3908 62.9142 89.3585 60.655 86.9416 60.655C84.4972 60.655 84.1201 62.5688 84.1201 64.5439V72.0327H79.4175L79.4141 56.8929Z" fill="#0A66C2"/>
<path d="M122.18 62.7017C122.189 62.3202 122.121 61.9409 121.979 61.5866C121.837 61.2324 121.625 60.9106 121.355 60.6409C121.085 60.3711 120.763 60.1589 120.409 60.0173C120.054 59.8756 119.675 59.8073 119.293 59.8165C118.472 59.7654 117.663 60.0392 117.041 60.5789C116.42 61.1187 116.035 61.881 115.97 62.7017H122.18ZM126.164 69.4127C125.383 70.3603 124.4 71.1223 123.288 71.6434C122.176 72.1645 120.961 72.4318 119.733 72.4258C115.031 72.4258 111.266 69.2868 111.266 64.426C111.266 59.5652 115.03 56.4277 119.733 56.4277C124.129 56.4277 126.885 59.5628 126.885 64.426V65.9005H115.97C116.106 66.7367 116.54 67.4956 117.191 68.0374C117.842 68.5792 118.667 68.8674 119.514 68.8489C120.184 68.8447 120.841 68.6732 121.427 68.35C122.014 68.0268 122.51 67.5621 122.87 66.9982L126.164 69.4127Z" fill="#0A66C2"/>
<path d="M136.687 60.5524C134.336 60.5524 132.925 62.1224 132.925 64.41C132.925 66.6976 134.336 68.269 136.687 68.269C139.039 68.269 140.453 66.7015 140.453 64.41C140.453 62.1184 139.041 60.5524 136.687 60.5524ZM144.782 72.033H140.453V70.0246H140.389C139.845 70.7551 139.141 71.3504 138.33 71.764C137.519 72.1776 136.624 72.3984 135.713 72.4092C131.195 72.4092 128.219 69.1483 128.219 64.5368C128.219 60.3016 130.853 56.4127 135.182 56.4127C137.129 56.4127 138.946 56.9451 140.013 58.421H140.075V49.6914H144.782L144.782 72.033Z" fill="#0A66C2"/>
<path d="M176.529 72.0419H171.826V64.6775C171.826 62.9214 171.795 60.6607 169.38 60.6607C166.931 60.6607 166.556 62.5741 166.556 64.5497V72.0414H161.854V56.8972H166.368V58.9668H166.431C166.883 58.1944 167.536 57.5589 168.32 57.1281C169.105 56.6973 169.991 56.4873 170.885 56.5205C175.652 56.5205 176.531 59.6556 176.531 63.7341L176.529 72.0419ZM156.548 54.8271C156.008 54.8272 155.48 54.6672 155.031 54.3675C154.582 54.0677 154.233 53.6415 154.026 53.1429C153.819 52.6443 153.765 52.0956 153.87 51.5662C153.976 51.0368 154.235 50.5505 154.617 50.1688C154.999 49.7871 155.485 49.5271 156.014 49.4217C156.544 49.3163 157.092 49.3703 157.591 49.5767C158.09 49.7832 158.516 50.1329 158.816 50.5816C159.116 51.0303 159.276 51.5579 159.276 52.0977C159.276 52.456 159.206 52.8109 159.068 53.142C158.931 53.4731 158.73 53.774 158.477 54.0275C158.224 54.2809 157.923 54.482 157.592 54.6192C157.261 54.7564 156.906 54.827 156.548 54.8271ZM158.899 72.0419H154.191V56.8972H158.899V72.0419ZM178.873 45.0022H151.828C151.214 44.9952 150.623 45.2323 150.184 45.6612C149.745 46.0902 149.494 46.6759 149.486 47.2898V74.4466C149.494 75.0607 149.744 75.6469 150.183 76.0763C150.623 76.5057 151.214 76.7432 151.828 76.7367H178.873C179.488 76.7444 180.082 76.5075 180.522 76.0782C180.963 75.6488 181.216 75.062 181.224 74.4466V47.2878C181.215 46.6728 180.963 46.0863 180.522 45.6574C180.081 45.2285 179.488 44.9921 178.873 45.0002" fill="#0A66C2"/>
</g>
</g>
<defs>
<filter id="filter0_dd_2540_18731" x="0.0214844" y="0" width="237" height="145" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="12"/>
<feGaussianBlur stdDeviation="8"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.624972 0 0 0 0 0.620887 0 0 0 0 0.825163 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2540_18731"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_2540_18731"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.346977 0 0 0 0 0.342936 0 0 0 0 0.553076 0 0 0 0.05 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_2540_18731" result="effect2_dropShadow_2540_18731"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2540_18731" result="shape"/>
</filter>
<clipPath id="clip0_2540_18731">
<rect width="125" height="32" fill="white" transform="translate(56.0215 45)"/>
</clipPath>
</defs>
</svg>

    )
}