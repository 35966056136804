import React from "react";

export const Integrations = () => {
  return (
    <div className="w-screen mb-5 md:my-15 flex flex-col items-center overflow-hidden">
      <h1 className="text-[25px] md:text-[35px] font-semibold pt-10 tracking-tight md:tracking-normal">
        Seamlessly Integrated.
      </h1>
      <img
        src="/images/Integrations/integrations.png"
        className="w-[110%] md:w-[90%] max-w-[1200px]"
      />
      {/* <video
        src="https://s3.ap-southeast-2.amazonaws.com/apse2.public.media.subbie/media/video/Icon+Page+Animation+2.mp4"
        className="w-[170%] md:w-[90%] max-w-[1200px]"
        loop
        autoPlay
        muted
        playsInline
      /> */}
    </div>
  );
};
