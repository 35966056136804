export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateWorkEmail = (email) => {
    return String(email)
    .toLowerCase()
    .match(
      /^[a-zA-Z0-9._%+-]+@(?!gmail\.com)(?!yahoo\.com)(?!hotmail\.com)(?!outlook\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );
}

export const validatePhoneNumber = (phone) => {
  return String(phone).match(/^(\(0[1-9]\)|0[1-9])?(-?[0-9]){9,10}$/);
};

export const validateUrl = (url) => {
  if (!url?.length) return true;

  if (url.includes(" ")) return null;

  const res = String(url).match(
    /^((?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~#?&\/=]*|)$/
  );

  return res;
};

export const validateInstagramUrl = (url) => {
  if (!url?.length) return true;

  if (url.includes(" ")) return null;

  const res = String(url).match(
    /^((?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~#?&\/=]*|)$/
  );

  return res;
};
