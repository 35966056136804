import React from "react";

export const Content = () => {
  return (
    <div className="w-full bg-[#F9F9FF] px-[5%] py-10 md:py-20 md:h-fit mx-auto flex flex-col justify-center items-center p-4 ">
      <div className="flex flex-col w-full gap-4 md:gap-12 lg:flex-row justify-center">
        <div className="bg-[#565ADD] text-white rounded-2xl px-10 py-6 text-xl md:text-[40px] font-normal md:leading-[56px] text-center">
          <span>
            Today’s sellers are <span className="font-extrabold">guessing</span>
          </span>
          <br />
          <span>their way through deals</span>
        </div>
        <div className="bg-[#565ADD] text-white rounded-2xl px-10 py-6 text-xl md:text-[40px] font-normal md:leading-[56px] text-center">
          <span>50% of those deals end in</span>
          <br />
          <span className="font-extrabold"> "No Decision"</span>
        </div>
      </div>

      <div className=" mt-10 text-[20px] md:text-[40px] font-medium text-center tracking-tight md:tracking-normal">
        Stop training sellers to become better
      </div>
      <div className="text-[31px] md:text-[40px] font-medium text-center tracking-tight md:tracking-normal">
        <div>
          Guide them to <span className="font-semibold text-[#565ADD]">do</span>{" "}
          better
        </div>
      </div>
    </div>
  );
};
